<template>
<div class="dashboard">
  <div class="wrapper wrapper-content animated fadeIn">

    <div class="p-w-md m-t-sm relative">
      <div class="loading fixed" v-if="initLoading"> <loader type="square-donut" /> </div>

      <div class="row">

        <div class="col-sm-4 m-t-sm w300">
          <h1 class="m-b-xs">
            {{latestChartItem.reserve_count}} 건
          </h1>
          <small> 금일예약 </small>

          <div class="graph clickable m-sm" @click.prevent="setMode('luggage-analytics')">
            <sparkline width="200" height="50" :tooltipProps="paidTooltipProps" :tooltipStyles="tooltipStyles">
              <sparklineBar :data="paidItems" :limit="paidItems.length" :min="0" :styles="paidStyles" />
            </sparkline>
          </div>

          <div class="row counting">
            <div class="counting-item col-4">
              <small class="stats-label">금일예약(건)</small>
              <h4>{{latestChartItem.reserve_count}}</h4>
            </div>
            <div class="counting-item col-4">
              <small class="stats-label">이용완료(건)</small>
              <h4>{{latestChartItem.purchase_count}}</h4>
            </div>
            <div class="counting-item col-4">
              <small class="stats-label">구매금액</small>
              <h4>{{latestChartItem.total_amount}}</h4>
            </div>
          </div>
        </div>

        <div class="col-sm-4 m-t-sm w300">
          <h1 class="m-b-xs">
            {{latestChartItem.cancel_count}} 건
          </h1>
          <small> 금일취소 </small>

          <div class="graph clickable m-sm" @click.prevent="setMode('cancelled-analytics')">
            <sparkline width="200" height="50" :tooltipProps="cancelledTooltipProps" :tooltipStyles="tooltipStyles">
              <sparklineBar :data="cancelledItems" :limit="cancelledItems.length" :min="0" :styles="cancelledStyles" />
            </sparkline>
          </div>

          <div class="row counting">
            <div class="counting-item col-4">
              <small class="stats-label">취소율</small>
              <!-- 오늘 구매건수 대비 취소건수 -->
              <h4>{{getRate({total:latestChartItem.reserve_count}, latestChartItem.cancel_count, '%')}}</h4>
            </div>
            <!-- <div class="counting-item col-4">
              <small class="stats-label">Total Cancelled</small>
              <h4>{{latestChartItem.acc_cancel_count}}</h4>
            </div> -->
            <div class="counting-item col-4">
              <small class="stats-label">누적 취소율</small>
              <!-- 총 구매건수 대비 취소건수 -->
              <h4>{{getRate({total: numberOfTickets+numberOfCancel}, numberOfCancel, '%')}}</h4>
            </div>
          </div>
        </div>

        <div class="col-sm-4 m-t-sm w300">
          <h1 class="m-b-xs">
            {{latestChartItem.joiner_count}} 명
          </h1>
          <small> 금일가입자 </small>

          <div class="graph clickable m-sm" @click.prevent="setMode('user-analytics')">
            <sparkline width="200" height="50" :tooltipProps="userTooltipProps" :tooltipStyles="tooltipStyles">
              <sparklineBar :data="userItems" :limit="userItems.length" :min="0" :styles="userStyles" />
            </sparkline>
          </div>

          <div class="row counting">
            <div class="counting-item col-4">
              <small class="stats-label">주간 가입자수</small>
              <h4>{{inWeekJoinCounts}}</h4>
            </div>
            <div class="counting-item col-4">
              <small class="stats-label">월간 가입자수</small>
              <h4>{{inMonthJoinCounts}}</h4>
            </div>
            <div class="counting-item col-4">
              <small class="stats-label">전일대비</small>
              <h4 :class="{'increase': currentJoinRate > 0, 'decrease': currentJoinRate < 0}">{{currentJoinRate.toFixed(1) + '%'}} </h4>
            </div>
          </div>
        </div>

        <table class="table small m-t-sm">
          <tbody>
            <tr>
              <td>
                <strong>{{numberOfTickets}}</strong> Tickets
              </td>
              <td>
                <strong>{{numberOfCancel}}</strong> Cancelled
              </td>
              <td>
                <strong>{{numberOfUsers}}</strong> Users
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div class="row">

        <div class="analytics-dashboard col-lg-12">

          <div class="luggage-analytics" v-if="mode === 'luggage-analytics'">
            <div class='hover-item' v-if="hoverItem">
              <span class="label">{{hoverItem.base_date}}</span>
              <ul>
                <!-- <li class='total'> {{hoverItem.total}} </li> -->
                <li class='joined-count'> {{hoverItem.joiner_count}} </li>
                <li class="reserve-count"> {{hoverItem.reserve_count}} </li>
                <li class='paid-count'> {{hoverItem.purchase_count}} </li>
                <!-- <li class='paid-rate'> {{hoverItem.paid_rate}}% </li> -->
                <li class='cancelled-count'> {{hoverItem.cancel_count}} </li>
                <!-- <li class='cancelled-rate'> {{hoverItem.cancelled_rate}}% </li> -->
              </ul>
            </div>

            <div class="chart">
              <chart-area
                v-if="chartItems"
                id="luggage-analytics-chart-area"
                :hover-callback="onHover"
                :smooth="chartOptions.smooth"
                :fill-opacity="chartOptions.fillOpacity"
                :hide-hover="chartOptions.hideHover"
                :behave-like-line="chartOptions.behaveLikeLine"
                :data="chartItems"
                :xkey="chartOptions.xkey"
                :ykeys="chartOptions.ykeys"
                :labels="chartOptions.labels"
                :grid="chartOptions.grid"
                :line-width="chartOptions.lineWidth"
                :line-colors="chartOptions.lineColors"
                :point-size="chartOptions.pointSize"
                :point-fill-colors="chartOptions.pointFillColors"
                :point-stroke-colors="chartOptions.pointStrokeColors"
                grid-text-weight="bold"
                resize="true">
              </chart-area>
            </div>
          </div>

          <div class="paid-analytics" v-if="mode === 'paid-analytics'">
            <div class='hover-item' v-if="hoverItem">
              <span class="label">{{hoverItem.base_date}}</span>
              <ul>
                <li class='total-paid'> {{hoverItem.total_paid}} </li>
                <li class='paid'> {{hoverItem.paid_1time}} / {{hoverItem.paid_2times}} / {{hoverItem.paid_3times}} </li>
              </ul>
            </div>

            <div class="chart">
              <chart-area
                v-if="chartItems"
                id="paid-analytics-chart-area"
                :hover-callback="onHover"
                :smooth="chartOptions.smooth"
                :fill-opacity="chartOptions.fillOpacity"
                :hide-hover="chartOptions.hideHover"
                :behave-like-line="chartOptions.behaveLikeLine"
                :data="chartItems"
                :xkey="chartOptions.xkey"
                :ykeys="chartOptions.ykeys"
                :labels="chartOptions.labels"
                :grid="chartOptions.grid"
                :line-width="chartOptions.lineWidth"
                :line-colors="chartOptions.lineColors"
                :point-size="chartOptions.pointSize"
                :point-fill-colors="chartOptions.pointFillColors"
                :point-stroke-colors="chartOptions.pointStrokeColors"
                grid-text-weight="bold"
                resize="true">
              </chart-area>
            </div>
          </div>

          <div class="cancelled-analytics" v-if="mode === 'cancelled-analytics'">
            <div class='hover-item' v-if="hoverItem">
              <span class="label">{{hoverItem.base_date}}</span>
              <ul>
                <li class='cancelled-count'> {{hoverItem.cancel_count}} </li>
                <li class='total-cancelled'> {{hoverItem.acc_cancel_count}} </li>
                <!-- <li class='cancelled'> {{hoverItem.cancelled_1time}} / {{hoverItem.cancelled_2times}} / {{hoverItem.cancelled_3times}} </li> -->
              </ul>
            </div>

            <div class="chart">
              <chart-area
                v-if="chartItems"
                id="cancelled-analytics-chart-area"
                :hover-callback="onHover"
                :smooth="chartOptions.smooth"
                :fill-opacity="chartOptions.fillOpacity"
                :hide-hover="chartOptions.hideHover"
                :behave-like-line="chartOptions.behaveLikeLine"
                :data="chartItems"
                :xkey="chartOptions.xkey"
                :ykeys="chartOptions.ykeys"
                :labels="chartOptions.labels"
                :grid="chartOptions.grid"
                :line-width="chartOptions.lineWidth"
                :line-colors="chartOptions.lineColors"
                :point-size="chartOptions.pointSize"
                :point-fill-colors="chartOptions.pointFillColors"
                :point-stroke-colors="chartOptions.pointStrokeColors"
                grid-text-weight="bold"
                resize="true">
              </chart-area>
            </div>
          </div>

          <div class="user-analytics" v-if="mode === 'user-analytics'">
            <div class='hover-item' v-if="hoverItem">
              <span class="label">{{hoverItem.base_date}}</span>
              <ul>
                <li class='joined-count'> {{hoverItem.joiner_count}} </li>
                <!-- {{hoverItem.joiner_count}} -->
              </ul>
            </div>

            <div class="chart">
              <chart-area
                v-if="chartItems"
                id="user-analytics-chart-area"
                :hover-callback="onHover"
                :smooth="chartOptions.smooth"
                :fill-opacity="chartOptions.fillOpacity"
                :hide-hover="chartOptions.hideHover"
                :behave-like-line="chartOptions.behaveLikeLine"
                :data="chartItems"
                :xkey="chartOptions.xkey"
                :ykeys="chartOptions.ykeys"
                :labels="chartOptions.labels"
                :grid="chartOptions.grid"
                :line-width="chartOptions.lineWidth"
                :line-colors="chartOptions.lineColors"
                :point-size="chartOptions.pointSize"
                :point-fill-colors="chartOptions.pointFillColors"
                :point-stroke-colors="chartOptions.pointStrokeColors"
                grid-text-weight="bold"
                resize="true">
              </chart-area>
            </div>
          </div>

        </div>


      </div>

      <div class="row m-t-md">
        <div class="col-lg-12">
          <div class="notice">* 물품개수부터 우측열의 데이터는 체크아웃된 티켓 기준으로 생성됩니다.</div>
          <div class="notice">* 아래 통계는 2021년 1월 1일부터 생성된 데이터 입니다. (이전 데이터는 별도로 백업됨)</div>
          <div class="ibox">
            <div class="ibox-content">
              <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
                <thead>
                  <tr class="fields">
                    <th scope="col">날짜</th>
                    <th scope="col">가입</th>
                    <th scope="col">예약</th>
                    <th scope="col">이용완료</th>
                    <th scope="col">취소</th>
                    <th scope="col">물품개수(작은물품+큰물품)</th>
                    <th scope="col">결제(구매-할인)</th>
                    <th scope="col">누적이용완료</th>
                    <th scope="col">누적취소</th>
                    <th scope="col">누적결제(구매-할인)</th>
                    <th scope="col">횟수별 누적구매(1회/2회/3회이상)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in items">
                    <td>{{row.base_date}}</td>
                    <td>{{row.joiner_count}}</td>
                    <td>{{row.reserve_count}}</td>
                    <td>{{row.purchase_count}}</td>
                    <td>{{row.cancel_count}}</td>
                    <td><strong>{{row.small_count+row.big_count}}</strong> ({{row.small_count}} + {{row.big_count}})</td>
                    <td><strong>₩{{Number(row.payment_amount).toLocaleString('ko-KR')}}</strong> ({{Number(row.total_amount).toLocaleString('ko-KR')}} - {{Number(row.discount_amount).toLocaleString('ko-KR')}}) </td>
                    <td>{{row.acc_purchase_count}}</td>
                    <td>{{row.acc_cancel_count}}</td>
                    <td><strong>₩{{Number(row.acc_payment_amount).toLocaleString('ko-KR')}}</strong> ({{Number(row.acc_total_amount).toLocaleString('ko-KR')}} - {{Number(row.acc_discount_amount).toLocaleString('ko-KR')}})</td>
                    <td>{{row.acc_first_count}} / {{row.acc_second_count}} / {{row.acc_third_count}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="13" class="relative" valign="center">
                      <div class="more" v-if="hasMoreData">
                        <div class="loading" v-if="loading"> <loader type="square-scale-ripple" /> </div>
                        <button v-if="!loading" @click="onClickMore">More</button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>

const chartOptions = {
  'luggage-analytics': {
    hideHover: 'false',
    behaveLikeLine: true,
    grid: true,
    xkey: 'base_date',
    ykeys: [ "total", "joiner_count", "purchase_count", "cancel_count" , 'reserve_count'],
    labels: [ "Total", "Joiner Count", "Paid Count", "Cancelled Count", 'Reserve Count'],
    pointSize: 0.5,
    pointStrokeColors: [ '#999', 'purple', '#1C84C6', '#d93025', '#949494' ],
    pointFillColors: [ '#ffffff', '#ffffff', '#ffffff', '#ffffff', "#ffffff" ],
    fillOpacity: '0.4',
    smooth: true,
    lineWidth: 1,
    lineColors: [ '#999', 'purple', '#1C84C6', '#d93025', "#949494" ],
  },
  'paid-analytics': {
    hideHover: 'false',
    behaveLikeLine: true,
    grid: true,
    xkey: 'date',
    ykeys: [
      "paid_1time", "paid_2times", "paid_3times"
    ],
    labels: [
      "1Time Paid", "2Times Paid", "3Times Paid"
    ],
    pointSize: 0.5,
    pointStrokeColors: [
      '#1C84C6', '#1C84C6', '#1C84C6'
    ],
    pointFillColors: [
      '#ffffff', '#ffffff', '#ffffff',
    ],
    fillOpacity: '0.4',
    smooth: true,
    lineWidth: 1,
    lineColors: [
      '#1C84C6', '#1C84C6', '#1C84C6'
    ],
  },
  'cancelled-analytics': {
    hideHover: 'false',
    behaveLikeLine: true,
    grid: true,
    xkey: 'date',
    ykeys: [
      "cancel_count"
      // , "cancelled_1time", "cancelled_2times", "cancelled_3times"
    ],
    labels: [
      "Cancelled"
      // , "1Time Cancelled", "2Times Cancelled", "3Times Cancelled"
    ],
    pointSize: 0.5,
    pointStrokeColors: [
      '#d93025'
      //, '#d93025', '#d93025', '#d93025'
    ],
    pointFillColors: [
      '#ffffff'
      //, '#ffffff', '#ffffff', '#ffffff'
    ],
    fillOpacity: '0.4',
    smooth: true,
    lineWidth: 1,
    lineColors: [
      '#d93025'
      //, '#d93025', '#d93025', '#d93025'
    ],
  },
  'user-analytics': {
    hideHover: 'false',
    behaveLikeLine: true,
    grid: true,
    xkey: 'base_date',
    ykeys: [
      "joiner_count"
    ],
    labels: [
      "Joined"
    ],
    pointSize: 0.5,
    pointStrokeColors: [
      'purple'
    ],
    pointFillColors: [
      '#ffffff', '#ffffff', '#ffffff'
    ],
    fillOpacity: '0.4',
    smooth: true,
    lineWidth: 1,
    lineColors: [
      'purple'
    ],
  }
}

export default {
  mounted() {
    this.loadData({ page: 1, size: this.initSize, loading: true })
  },
  data() {
    return {
      mode: 'luggage-analytics',
      // mode: 'paid-analytics',

      currentJoinCounts: 0,
      currentJoinRate: 0,

      numberOfCancel: 0,
      numberOfTickets: 0,
      numberOfUsers: 0,

      tooltipStyles: {
        position: 'fixed',
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '5px',
        minWidth: '30px',
        padding: '8px',
        color: '#333',
        fontSize: '12px'
      },

      paidStyles: {
        stroke: '#1C84C6',
        fill: '#1C84C6'
      },
      paidTooltipProps: {
        formatter: (item) => {
          let index = item.index
          let chartItem = this.chartItems[index]
          return `${chartItem.base_date}: ${chartItem.purchase_count}건`
        }
      },

      cancelledStyles: {
        stroke: '#d93025',
        fill: '#d93025'
      },
      cancelledTooltipProps: {
        formatter: (item) => {
          let index = item.index
          let chartItem = this.chartItems[index]
          return `${chartItem.base_date}: ${chartItem.cancel_count}건`
        }
      },

      userStyles: {
        stroke: 'purple',
        fill: 'purple'
      },
      userTooltipProps: {
        formatter: (item) => {
          let index = item.index
          let chartItem = this.chartItems[index]
          return `${chartItem.base_date}: ${chartItem.joiner_count}명`
        }
      },

      initLoading: true,
      loading: false,
      page: 1,
      size: 30,
      perSize: 30,
      initSize: 30,
      total: 0,
      items: [],
      chartItems: [],
      hoverItem: null,
      // trafficItems: [],
      // userItems: []
    }
  },
  computed: {
    hasMoreData() {
      return this.items.length < this.total ? true : false
    },
    chartOptions() {
      return chartOptions[this.mode] || {}
    },
    latestChartItem() {
      let item = this._.last(this.chartItems)
      return item || {
        base_date: '',
        total: 0, joiner_count: 0, purchase_count: 0, total_paid: 0, paid_1time: 0, paid_2times: 0, paid_3times: 0,
        cancel_count: 0, total_cancelled: 0, cancelled_1time: 0, cancelled_2times: 0, cancelled_3times: 0
      }
    },
    paidItems() {
      let items = this._.map(this.items, item => { return item.purchase_count })
        return this._.reverse(this._.slice(items, 0, Math.min(30, items.length)))
    },
    cancelledItems() {
      let items = this._.map(this.items, item => { return item.cancel_count })
      return this._.reverse(this._.slice(items, 0, Math.min(30, items.length)))
    },
    userItems() {
      let items = this._.map(this.items, item => { return item.joiner_count })
      return this._.reverse(this._.slice(items, 0, Math.min(30, items.length)))
    },
    inWeekJoinCounts() {
      let items = this._.map(this.items, item => { return item.joiner_count })
      let count = 0
      this._.forEach(this._.slice(items, 0, Math.min(7, items.length)), (value) => {
        count += value
      })
      return count
    },
    inMonthJoinCounts() {
      let items = this._.map(this.items, item => { return item.joiner_count })
      let count = 0
      this._.forEach(this._.slice(items, 0, Math.min(30, items.length)), (value) => {
        count += value
      })
      return count
    }
  },
  methods: {
    setMode(mode) {
      this.$data.mode = ''
      setTimeout(() => {
        this.$data.mode = mode
      }, 0)
    },

    loadData(options = {}) {
      if (this.loading) {
        this.$toast.warning("이미 데이타를 불러오는 중입니다.")
        return
      }

      let { page, size, loading, overwrite } = options
      this.$data.page = page || this.page
      this.$data.size = size || this.size
      this.$data.loading = true

      if (loading === true) { this.$data.initLoading = true }

      let isFirstPage = this.page <= 1

      this.$store.dispatch('analytics/dashboard', { page: this.page, size: this.size })
        .then((data) => {
          let { items, total, cancelled, tickets, users } = data
          let listItems = isFirstPage && !overwrite ? [] : this.items
          let nextIndex = this.size * (this.page - 1)

          let currentJoinRate = this.currentJoinRate
          let currentJoinCounts = this.currentJoinCounts

          this._.forEach(items, (item, i) => {

            item.paid_rate = this.getRate(item, item.purchase_count)
            item.cancelled_rate = this.getRate(item, item.cancel_count)

            if (!overwrite || !listItems[i + nextIndex]) {
              listItems.push(item)
            }
            else {
              listItems[i + nextIndex] = item
            }

            if (isFirstPage) {
              if (i === 0) {
                currentJoinCounts = item.joiner_count
              }
              else if (i === 1) {
                let diff = currentJoinCounts - item.joiner_count
                // 어제 가입자수 대비 오늘 가입자수 비율
                currentJoinRate = item.joiner_count > 0 ? (diff / item.joiner_count) * 100 : 0
              }
            }
          })

          this.$data.chartItems = this._.reverse(this._.clone(listItems))
          this.$data.items = listItems
          this.$data.total = total

          this.$data.numberOfCancel = cancelled
          this.$data.numberOfTickets = tickets
          this.$data.numberOfUsers = users
          this.$data.currentJoinRate = currentJoinRate
          this.$data.currentJoinCounts = currentJoinCounts

          if (isFirstPage && this.size > this.perSize) {
            this.$data.page = (this.$data.size / this.perSize)
            this.$data.size = this.initSize
          }
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.initLoading = false
          this.$data.loading = false
        })
    },

    getRate(item, value, suffix) {
      let { total } = item
      let rate = value > 0 ? (value / total) * 100 : 0
      if (suffix) {
        return rate.toFixed(2) + suffix
      }
      return rate.toFixed(2)
    },

    onHover(index) {
      let item = this.chartItems[index]
      this.$data.hoverItem = item
    },

    onClickMore() {
      let lastPage = this.items.length > 0 ? Math.ceil((this.items.length-1) / this.initSize) : 1
      this.loadData({page:lastPage + 1, size: this.initSize })
    },
  }
}
</script>
